import React, { useEffect, useState } from 'react';
import axios from "axios";
import bcrypt from "bcryptjs";
import { useSelector } from 'react-redux';
import SideNav from "../nav/SideNav";

import { Flex, Box,  Stack, FormControl, FormLabel, Input, Button, } from '@chakra-ui/react'


const ResetPassword = () => {
  const [existingHashedPassword, setExistingHashedPassword] = useState("");
  const [existingPassword, setExistingPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const auth = useSelector(state => state.auth);



  useEffect(() => {
    // Make an axios GET request to retrieve the user's current profile information
    axios.post('/api/users/get-profile', { id: auth.user.id })
      .then(response => {
        //console.log(response.data.password);
        setExistingHashedPassword(response.data.password)
      })
      .catch(error => {
        console.error(error);
      });

  }, []);

  const handleSubmit = async (e) => {

    // Clear Errors on the start of submit
    setError('');
    setSubmitLoading(true)


    e.preventDefault();

    if (newPassword.trim() === "" || confirmPassword.trim() === "" || existingPassword.trim() === "") {
      setError("Please fill in all the fields.");
      setSubmitLoading(false)
      return;
    }


    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match.");
      setSubmitLoading(false)
      return;
    }

    try {
      // compare existing password with the hashed password in the database
      const match = await bcrypt.compare(existingPassword, existingHashedPassword);
      if (!match) {
        setError("Incorrect existing password.");
        setSubmitLoading(false)
        // setExistingPassword('');
        // setNewPassword('');
        // setConfirmPassword('');
        // setError('');
        return;
      }

      // hash the new password
      const salt = await bcrypt.genSalt();
      const hashedNewPassword = await bcrypt.hash(newPassword, salt);

      //console.log(hashedNewPassword);

      // update the password in the database
      await axios.patch("/api/users/update-password", {
        newPassword: hashedNewPassword, id: auth.user.id
      });

      setSuccess("Password reset successfully.");
      setSubmitLoading(false)

      // setExistingPassword('');
      // setNewPassword('');
      // setConfirmPassword('');
    } catch (err) {
      setError("An error occurred. Please try again later.");
      setSubmitLoading(false)

    }
  };

  return (

    <Flex borderWidth="1px" borderRadius="md">



      <form w='full' onSubmit={handleSubmit}>
        <Box
          py={{ base: '0', sm: '8' }}
          px={{ base: '2', sm: '8' }}
          w='500px'
        >
          <Stack w='full' spacing={4}>
            <FormControl>
              <FormLabel htmlFor="existingPassword">Existing Password:</FormLabel>
              <Input
              
                placeholder='Enter your existing Password'
                variant="outline"
                size="md"
                type="password"
                name="existingPassword"
                id="existingPassword"
                value={existingPassword}
                onChange={(e) => setExistingPassword(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="newPassword">New Password:</FormLabel>
              <Input
               placeholder='Enter a new password'
                variant="outline"
                size="md"
                type="password"
                name="newPassword"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="confirmPassword">Confirm Password:</FormLabel>
              <Input
                placeholder='Confirm new password'
                variant="outline"
                size="md"
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>
            <Button
              size='md'
              type="submit"
              colorScheme="blue"
              isLoading={submitLoading}
              loadingText="Resetting"
              spinnerPlacement="start"
            >
              Reset Password
            </Button>
            {error && <div>{error}</div>}
            {success && <div>{success}</div>}
          </Stack>
        </Box>
      </form>

    </Flex>

  );
};

export default ResetPassword;
