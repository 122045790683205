import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const PageHeading = ({ heading, subHeading }) => {
  return (
    <Box mb={4}>
      <Text
        variant="H1"
      >
        {heading}
      </Text>
      <Text
        fontSize="16px"       // updated font size
        fontWeight="400"      // regular
        lineHeight="24px"     // updated line height
        letterSpacing="0"     // updated letter spacing
        color="#2D2B32"       // updated color

      >
        {subHeading}
      </Text>
    </Box>
  );
};

export default PageHeading;
