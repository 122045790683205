import React from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Link,
  Box,
  Text,
  Flex,
  VStack,
  HStack,
  Avatar,
  Image,
} from "@chakra-ui/react";

function PostModal({ isOpen, onClose, data, profile, isSubscribed }) {
  const authenticatedUser = useSelector((state) => state.auth);

  // Determine if the viewed post belongs to the authenticated user
  const isSameUser =
    data &&
    authenticatedUser &&
    data.username === authenticatedUser.user.username;

  if (!data) {
    return null; // Or a loader, or any placeholder you see fit
  }

  console.log(data);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={true}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        maxW="90vw"
        maxH="90vh"
        mt="5vh"
        mb="5vh"
        overflowY="hidden"
      >
        <ModalCloseButton mt={3} p={0} />

        <ModalHeader>
          <Flex
            direction={{ base: "column", md: "row" }} // Stack vertically on small screens, horizontally on medium and up
            width="full"
            justifyContent="space-between" // Maintain the space-between for horizontal layout
            alignItems="left" // Ensure items are centered in the Flex container
            pr={{ base: "0", md: "16" }} // Adjust padding-right responsively if needed
          >
            <Flex alignItems="center">
              <Avatar
                mr={2}
                size="md"
                name={data.fullName}
                src={data.profileImage}
              />
              <VStack alignItems="flex-start" spacing={0}>
                <Text mb={0} fontSize="18px">
                  {data.fullName}
                </Text>
              </VStack>
            </Flex>
            {!isSameUser && (
              <Flex
                mt={{ base: 4, md: 0 }}
                justifyContent="center"
                direction={{ base: "column", md: "row" }} // Stack vertically on small screens, horizontally on medium and up
                spacing="8px"
              >
                {/* If the user is subscribed, show a different link */}
                {isSubscribed ? (
                  <Box>
                      {data.postType === "Design" ? (
                        <Link
                          href={data.url}
                          target="_blank"
                          bg="#1964FF"
                          borderRadius="6px"
                          color="white"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          padding="0px 14px"
                          height="44px"
                          _hover={{ color: "white", bg: "#0e4bef" }}
                          fontSize="16px"
                          fontWeight="600"
                        >
                          View Design
                        </Link>
                      ) : (
   
                          <Link
                          href={data.codeURLs && data.codeURLs[1].CodeFileLink}
                          download
                          bg="#1964FF"
                          borderRadius="6px"
                          color="white"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          padding="0px 14px"
                          height="44px"
                          _hover={{ color: "white", bg: "#0e4bef" }}
                          fontSize="16px"
                          fontWeight="600"
                        >
                          Download Code
                        </Link>
                      )}
                  </Box>
                ) : (
                  <>
                    <Link
                      href={`/register/subscriber?creator=${profile._id}&interval=yearly`}
                      bg="white"
                      border="1.5px solid"
                      borderColor="#F1F1F1"
                      borderRadius="6px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      padding="0px 14px"
                      height="44px"
                      _hover={{ bg: "#F1F1F1" }}
                      fontSize="16px"
                      mr={2} // Add margin right to separate the links
                    >
                      View for ${profile.plan.yearly}/year
                    </Link>
                    <Link
                      href={`/register/subscriber?creator=${profile._id}&interval=monthly`}
                      bg="#1964FF"
                      borderRadius="6px"
                      color="white"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      padding="0px 14px"
                      height="44px"
                      _hover={{ color: "white", bg: "#0e4bef" }}
                      fontSize="16px"
                    >
                      View for ${profile.plan.monthly}/month
                    </Link>
                  </>
                )}
              </Flex>
            )}
          </Flex>
        </ModalHeader>

        <ModalBody overflowY="auto" maxH="calc(90vh - 60px)">
          <Box
            p={4}
            mb="10px"
            borderWidth={1}
            borderRadius="md"
            overflow="hidden"
          >
            <Image
              src={data.mediaLink}
              alt={data.username}
              w="100%" // Ensures image width is responsive to container width
              maxH="60vh" // Max height to ensure image fits within modal on most devices
              objectFit="contain" // Ensures full image is visible, may add empty space around the image
            />
          </Box>
          <Text mb={0} fontWeight="450" fontSize="28px">{data.title}</Text>
          <Text>{data.description}</Text>
          <Text>{data.tags}</Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default PostModal;
