import React from 'react';
import axios from 'axios';
import { Flex, Text, Image, HStack } from '@chakra-ui/react'
import dribbble from '../../Images/dribbble.png'

const CLIENT_ID = '6a9c5a5be3c6b06b8125b53d1a4f2c9a805b2083e952fab31fc3a7dd6c41bca0';
// Added a colon 8/13/24
const REDIRECT_URI = `${window.location.protocol}://${window.location.host}/oauth/dribbble`;

const DribbbbleLogin = () => {
  const handleLoginClick = () => {
    console.log(REDIRECT_URI);
    const url = `https://dribbble.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=public`;
    window.location.href = url;
  };

  return (
    <Flex>
      <HStack 
      align="center"
      spacing={2}
      as="button"
      fontFamily="'Basier Square', sans-serif"  // Final fontFamily overrides the earlier declaration
      bg="#ffffff"
      color="#0A090B"  // Corrected the color format to include '#'
      fontSize="16px"
      lineHeight="24px"
      fontWeight="600"
      border="1px solid #E7E7E7"
      borderRadius="8px"
      height="44px"
      px="16px"  // Use 'px' shorthand for horizontal padding
      transition="background-color 0.15s cubic-bezier(0, 0, 0.2, 1), color 0.15s cubic-bezier(0, 0, 0.2, 1)"
      onClick={handleLoginClick}
      _hover={{
        bg: '#F5F5F5',  // Example hover state change
        color: '#0A090B'
      }}
      >

        <Image boxSize='24px' objectFit='cover' src={dribbble} alt="Dribbble Logo"/>
        <Text variant="secondaryButton"  mb={0}>Upload Dribbble Shots</Text>
      </HStack>
    </Flex>
  );
};

export default DribbbbleLogin;