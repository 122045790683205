import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import NoSubscriptions from "../components/Home/NoSubscriptions";
import SubscribedPosts from "../components/Home/SubscribedPosts";

import EditAccountSettings from "../components/AccountSettings/EditAccountSettings";
import ResetPassword from "../components/AccountSettings/ResetPassword";
import CustomerMembershipSettings from "../components/AccountSettings/CustomerMembershipSettings";

import PageHeading from "../components/general/PageHeading";
import PageLayout from "../components/general/PageLayout";


import SideNav from "../components/nav/SideNav";
import { Tabs, Tablist, TabPanel, TabPanels, Tab, TabList, Flex, Box } from '@chakra-ui/react'

import '../index.css';

function AccountSettings() {

  const auth = useSelector(state => state.auth);

  useEffect(() => {
        if (!auth.isAuthenticated) {
        window.location.href = "/login";
      }
    }, []);
  

    return (
        <PageLayout activeLink='/edit-account-settings'>
          <Box w='full' p={2}>
          <PageHeading heading='Account Settings' subHeading='Manage Your Account and Subscriptions' /> 
            <Tabs w='full'>
              <TabList>
                <Tab color="#212121">General</Tab>
                <Tab color="#212121">Password</Tab>
                <Tab color="#212121">Subscriptions</Tab>
              </TabList>
    
              <TabPanels w='full'>
                <TabPanel w='full' px={0}>
                  <EditAccountSettings />
                </TabPanel>
                <TabPanel w='full' px={0}>
                  <ResetPassword />
                </TabPanel>
                <TabPanel w='full' px={0}>
                  <CustomerMembershipSettings />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </PageLayout>
      );
}

export default AccountSettings;
