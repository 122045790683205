import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useToast,
  Skeleton,
  Center,
  Spinner,
  HStack,
  Box,
  Tabs, Tablist, TabPanel, TabPanels, Tab, TabList,
} from "@chakra-ui/react";
import PageLayout from "../components/general/PageLayout";
import EditMembershipForm from "../components/MembershipSettings/EditMembershipForm";
import useProfile from "../customHooks/useProfile"; // Adjust the import path as necessary
import { useAuthUser } from "../customHooks/useAuthUser";
import UpdatePrices from "../components/MembershipSettings/UpdatePrices";
import PageHeading from "../components/general/PageHeading";

const EditMembershipDetails = () => {
  const authUser = useAuthUser(); // Assuming useAuthUser returns the user object with an id
  const { profileData, isLoading, error, updateProfile } = useProfile(
    authUser.id
  );
  const [localFormData, setLocalFormData] = useState({});
  const toast = useToast();
  const inputRef = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    console.log(profileData);
    // Correctly sync localFormData with profileData, considering the nested 'plan' structure
    setLocalFormData({
      ...profileData,
      ...profileData.plan, // Spread in values from 'plan' object
    });
    console.log(localFormData);
  }, [profileData]);

  useEffect(() => {
    if (
      !isLoading &&
      profileData?.plan &&
      (profileData.plan.monthly === 0 || profileData.plan.yearly === 0)
    ) {
      navigate("/dashboard");
    }
  }, [isLoading, profileData, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLocalFormData((prev) => ({
          ...prev,
          profileImage: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const updateData = {
      userId: authUser.id, // Assuming userId is correctly stored in localFormData
      name: localFormData.name,
      username: localFormData.username,
      // Adjust according to your actual form and backend model
      plan: {
        profileImage: localFormData.profileImage,
        bio: localFormData.bio,
        location: localFormData.location,
        portfolioLink: localFormData.portfolioLink,
        contactEmail: localFormData.contactEmail,
        monthly: localFormData.monthly,
        yearly: localFormData.yearly,
        // Include isActive if needed
      },
    };

    console.log(updateData);

    try {
      await updateProfile(updateData);
      toast({
        title: "Profile updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Error updating profile",
        description: err.message || "An error occurred, try again",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (isLoading)
    return (
      <PageLayout activeLink="/edit-membership-details">
        <Skeleton isLoaded={!isLoading}>
          <Center h="100vh">
            <Spinner />
          </Center>
          ;{/* Adjust the content as needed for your loading state */}
        </Skeleton>
      </PageLayout>
    );

  if (error) return <div>Error: {error}</div>;

  return (
    <PageLayout activeLink="/edit-membership-details">
      <Box w="full">
        <PageHeading
          heading="Channel Settings"
          subHeading="Manage your UIMarketplace channel"
        />
        <Tabs w="full">
          <TabList>
            <Tab color="#212121">Profile</Tab>
            <Tab color="#212121">Pricing</Tab>
          </TabList>

          <TabPanels w="full">
            <TabPanel w="full" px={0}>
              <EditMembershipForm
                formData={localFormData}
                handleChange={handleChange}
                handleImageChange={handleImageChange}
                handleSubmit={handleSubmit}
                submitLoading={isLoading}
                inputRef={inputRef}
                user={authUser}
              />
            </TabPanel>
            <TabPanel w="full" px={0}>
              <UpdatePrices />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </PageLayout>
  );
};

export default EditMembershipDetails;
