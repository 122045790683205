import React, { useState, useEffect } from 'react';

import SideNav from "../nav/SideNav";

import { Box, VStack, useBreakpointValue } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/react'; // Import a spinner component from your UI library


const PageLayout = ({activeLink, children}) => {

  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const flexDirection = useBreakpointValue({ base: "column", md: "row" });
  const paddingx = useBreakpointValue({ base: "8", md: "16" });
  const paddingy = useBreakpointValue({ base: "8", md: "8" });


  return (
    <Box px={paddingx} w="100%" h="100%" m={0} >
    <VStack>
      <Box w="100%" position="sticky" top={0} zIndex={1}>
        <SideNav activeLink={activeLink}/>
      </Box>

        <Box py={0} w="100%" h="100%">
        {children}
        </Box>
    </VStack>
    </Box>
  );
};


export default PageLayout;
