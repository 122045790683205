import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, GridItem, Box, Image, Center, Spinner, Badge, VStack, Heading, Text,useColorModeValue } from "@chakra-ui/react";
import { usePostsByUsername } from "../../customHooks/usePostsByUsername";

const PostsByUser2 = ({ userId, username }) => {
  const navigate = useNavigate();
  const { posts, loading, error, totalPages, setCurrentPage, hasMore } = usePostsByUsername(username);
  const sentinel = useRef(null);

  const handlePostClick = (post) => {
    navigate(`/u/${username}/post/${post._id}`);
  };
  const bgColor = useColorModeValue('gray.100', 'gray.700');


  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore && !loading) {
        setCurrentPage(prevPage => prevPage + 1);
      }
    }, { threshold: 1.0 });

    if (sentinel.current) {
      observer.observe(sentinel.current);
    }

    return () => observer.disconnect();
  }, [loading, hasMore, setCurrentPage]);

  if (loading && posts.length === 0) {
    return <Center h="100vh"><Spinner /></Center>;
  }

  if (error) {
    return <Text>Error loading posts.</Text>;
  }

  return (
    <Grid
      py="24px"
      templateColumns={{
        base: "repeat(1, 1fr)",
        sm: "repeat(2, 1fr)",
        md: "repeat(3, 1fr)",
        lg: "repeat(4, 1fr)",
      }}
      gap={12}
    >
      {posts?.length > 0 &&
        posts.map((post) => (
          <GridItem  _hover={{ cursor: 'pointer' }} key={post._id} onClick={() => handlePostClick(post)}>
            <Box
                      mb="10px"
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      bg={bgColor} // Adjust the color mode based background
                      // Set a fixed height or use aspectRatio to keep uniformity
                      aspectRatio="1" // This will make the box square, adjust ratio as needed
                      position="relative"
            >
              <Image
                src={post.mediaLink}
                alt={post.username}
                w="full"
                h="full"
                objectFit="cover"
                transition="0.3s ease-in-out"
                _hover={{
                  transform: "scale(1.05)",
                }}
              />
            </Box>
            <VStack align="start" spacing={1}>
              <Text mb={0} fontWeight="500" fontSize='16px'>{post.title}</Text>
              <Box>
                  {post.postType === 'Design' && <Badge px={2} borderRadius={2} colorScheme='green'>Design</Badge>}
                  {post.postType === 'Code' && <Badge px={2} borderRadius={2} colorScheme='purple'>Code</Badge>}
              </Box>
            </VStack>
          </GridItem>
        ))}
      <div ref={sentinel}></div> 
      {loading && hasMore && <Center><Spinner /></Center>}
    </Grid>
  );
};

export default PostsByUser2;

