import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  Heading,
  Text,
  Badge,
  useColorModeValue,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { FiImage, FiCode } from "react-icons/fi"; // Assuming these are the icons you want to use
import FeatherIcon from 'feather-icons-react';

import PageLayout from "../components/general/PageLayout";
import PageHeading from "../components/general/PageHeading";

function NewPost() {
  const bgColor = useColorModeValue("#ffffff");
  const hoverBgColor = useColorModeValue("#F8F8F8");
  const navigate = useNavigate();

  // Function to handle navigation
  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <PageLayout activeLink="/new/post">
      <PageHeading
        heading="New Post"
        subHeading="Share something new with your audience"
      />
      <Box direction="row" align="center" w="full" h="full" p={2} overflowY="auto">
        <HStack
          spacing={8}
          align="stretch"
          w="full"
        >
          {/* Design CTA */}
          <Box
            background="#FFFFFF"
            border="1px solid #ECECED"
            boxShadow="0px 5px 13px -5px rgba(10, 9, 11, 0.05), 0px 2px 4px -1px rgba(10, 9, 11, 0.02)"
            borderRadius="8px"
            as="button"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={4}
            _hover={{ bg: hoverBgColor }}
            onClick={() => handleNavigate("/new/post/design")}
            gap={2}
            w="full"
          >
            <Badge colorScheme="green" p={2} borderRadius="full">
              Design
            </Badge>
            <Heading size="lg">Design</Heading>
            <FeatherIcon size="48" icon="figma" />

            <Text mb={2}>Share your lastest design exploration</Text>
          </Box>

          {/* Code CTA */}
          <Box
            background="#FFFFFF"
            border="1px solid #ECECED"
            boxShadow="0px 5px 13px -5px rgba(10, 9, 11, 0.05), 0px 2px 4px -1px rgba(10, 9, 11, 0.02)"
            borderRadius="8px"
            as="button"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={4}
            _hover={{ bg: hoverBgColor }}
            onClick={() => handleNavigate("/new/post/code")}
            gap={2}
            w="full"
          >
            <Badge colorScheme="purple" p={2} borderRadius="full">
              Code
            </Badge>
            <Heading size="lg">Code</Heading>
            <FeatherIcon size="48" icon="code" />

            <Text mb={2}>Share your latest code snippets</Text>
          </Box>
        </HStack>
      </Box>
    </PageLayout>
  );
}

export default NewPost;
