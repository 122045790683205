import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import {
  Alert,
  InputGroup,
  ButtonGroup,
  Form,
  FormGroup,
} from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";

import SideNav from "../components/nav/SideNav";
import { useNavigate } from "react-router-dom";
import PageHeading from "../components/general/PageHeading";
import PageLayout from "../components/general/PageLayout";

import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Stack,
  Flex,
  HStack,
  Link,
  Avatar,
  Center,
  Badge,
  Box,
  Text,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  VStack,
} from "@chakra-ui/react";

import { Select } from "@chakra-ui/select";

// Onboarding Step 1
const OnboardingMembershipDetails = () => {
  // Initialize state for form data
  const [formData, setFormData] = useState({});

  //History routing
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  console.log(auth);
  //console.log(auth.user.stripeConnectedAccountID);

  const [error, setError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [SuccessfulBanner, setSuccessfulBanner] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [ErrorBanner, setErrorBanner] = useState(false);

  const inputRef = useRef();

  const fetchData = async () => {
    try {
      const response = await axios.post("/api/users/get-profile", {
        id: auth.user.id,
      });
      console.log(response);
      // Prepopulate the form with the user's current profile information
      setFormData({
        //location: response.data.plan.location,
        userId: auth.user.id,
        profileImage: response.data.plan.profileImage,
        username: response.data.username,
        name: response.data.name,
        bio: response.data.plan.bio,
        portfolioLink: response.data.plan.portfolioLink,
        contactEmail: response.data.plan.contactEmail,
        monthly: response.data.plan.monthly,
        yearly: response.data.plan.yearly,
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Show existing data in the form
  // Show existing data in the form
  useEffect(() => {
    if (!auth.isAuthenticated) {
      window.location.href = "/login";
    }

    fetchData();
  }, []);

  // Function to handle input changes and update form data in state
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    console.log(formData);
  };

  const handleUsernameChange = (event) => {
    // Convert username input to lowercase before setting it in state
    setFormData({
      ...formData,
      [event.target.name]: event.target.value.toLowerCase(),
    });
  };

  // function handleImageChange(event) {
  //   const file = event.target.files[0];
  //   setFormData(prevState => ({
  //     ...prevState,
  //     profileImage: URL.createObjectURL(file)
  //   }));
  // }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData((prevState) => ({
        ...prevState,
        profileImage: reader.result,
      }));
      console.log(reader.result);
    };
    reader.readAsDataURL(file);
    //setFormData({ ...formData, [e.target.name]: reader.result });
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitLoading(true);
    setErrorBanner(false);
    console.log(formData);
    
    setUsernameError("")

    //Make a POST request to update the profile information
    try {
      const response = await axios.post(
        "/api/users/onboarding-profile",
        formData
      );
      setSubmitLoading(false);
      setSuccessfulBanner(true);
      console.log(response.data);
      window.location.href = "/membership/onboarding/2";
      fetchData();

      setTimeout(() => {
        setSuccessfulBanner(true);
      }, 5000);
    } catch (error) {
      setUsernameError(error.response.data.message)
      console.log(error);
      setSubmitLoading(false);

    }
  };

  return (
    <PageLayout activeLink="/dashboard">
      <Box h="full" display="flex" flexDirection="column" >
        <Box w="800px">
          <PageHeading
            heading="Channel Settings"
            subHeading="These are your channel details, they are visible on your public profile"
          />
        </Box>

        <Box borderWidth="1px" borderRadius="md" px={4} w="100%">
          <form onSubmit={handleSubmit} className="">
            <Box py={{ base: "0", sm: "8" }} px={{ base: "2", sm: "8" }}>
              <Stack spacing={4}>
                {SuccessfulBanner && (
                  <Alert status="success"> Form submitted successfully! </Alert>
                )}
                {error && (
                  <Alert
                    variant="danger"
                    dismissible
                    onClose={() => setSuccessfulBanner(false)}
                  >
                    {error}
                  </Alert>
                )}

                <Stack spacing={4}>
                  {/* Profile image input */}

                  <FormControl>
                    <FormLabel htmlFor="email">Profile Image</FormLabel>

                    <HStack w="full" align="center" py="16px">
                      <Avatar
                        mb={2}
                        bg="#105DFB"
                        color="#ffffff"
                        fontSize="md"
                        name={auth.user.name}
                        src={formData.profileImage}
                        size="lg"
                        mr={0}
                      />

                      <Button
                        color="blue"
                        colorScheme="blue"
                        variant="subtle"
                        size="md"
                        onClick={() => {
                          inputRef.current.click();
                        }}
                      >
                        {" "}
                        Change Avatar{" "}
                      </Button>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={inputRef}
                        accept="image/png, image/jpeg, image/gif, image/webp"
                        name="profileImage"
                        id="profileImage"
                        onChange={handleImageChange}
                      />
                    </HStack>
                  </FormControl>

                  {/* Location input 
                  <FormControl>
                    <FormLabel htmlFor="location">Location</FormLabel>
                    <Select
                      value={formData.location}
                      id="location"
                      name="location"
                      onChange={handleChange}
                      placeholder="Select country">
                      {countries.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  */}

                  <HStack gap={4}>
                    {/* Username input */}
                    <FormControl isRequired>
                      <FormLabel htmlFor="email">Username</FormLabel>
                      <Input
                        variant="outline"
                        size="md"
                        placeholder="Enter your username"
                        type="text"
                        name="username"
                        id="username"
                        value={formData.username}
                        onChange={handleUsernameChange}
                        onKeyDown={(e) => {
                          // Prevent spaces from being entered
                          if (e.key === " " || e.key === "Spacebar") {
                            e.preventDefault();
                          }
                        }}
                      />
                       {usernameError && <Text color="red">{usernameError}</Text>}
                    </FormControl>
                  </HStack>

                  {/* Bio input */}
                  <FormControl>
                    <FormLabel htmlFor="email">About you</FormLabel>
                    <Input
                      variant="outline"
                      size="md"
                      placeholder="Tell us about yourself"
                      type="text"
                      name="bio"
                      id="bio"
                      value={formData.bio}
                      onChange={handleChange}
                    />
                  </FormControl>

                  {/* Portfolio link input */}
                  <FormControl>
                    <FormLabel htmlFor="email">Portfolio Link</FormLabel>
                    <Input
                      variant="outline"
                      size="md"
                      placeholder="Enter the link to your porfolio"
                      type="text"
                      name="portfolioLink"
                      id="portfolioLink"
                      value={formData.portfolioLink}
                      onChange={handleChange}
                    />
                  </FormControl>

                  {/* Submit button */}
                  <Button
                    type="submit"
                    variant="primaryButton"
                    isLoading={submitLoading}
                    loadingText="Updating"
                    spinnerPlacement="start"
                    width="175px"
                  >
                    Save & Continue
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </form>
        </Box>
      </Box>
    </PageLayout>
  );
};

export default OnboardingMembershipDetails;
