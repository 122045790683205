import React, { useEffect, useState, useRef } from "react";
import Logo2 from "../../Images/Logo2.png";

import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    HStack,
    Image,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue,
    Link,
    useBreakpointValue,
    background,
  } from "@chakra-ui/react";
  import {  ChevronDownIcon } from '@chakra-ui/icons'
  


const PublicNav = () => {
  const paddingy = useBreakpointValue({ base: "2", md: "2" });

    return(
      <Flex py={paddingy} as="header" align="center" justify="space-between" background="transparent">
        <Box>
        <Button p={0} style={{textDecoration: "none", background: "none"}} as={Link} href="/">
          <Image
            objectFit="cover"
            src={Logo2}
            alt="UI Marketplace"
            w="250px"
          />
          </Button>
          </Box>
      <HStack spacing={8}>
        <Button style={{textDecoration: "none"}} as="a" href="/login" variant="link">
          Log in
        </Button>
        <Button variant="primaryButton" as="a" href="/signup">
          Get started
        </Button>
      </HStack>
    </Flex>
    );
};

export default PublicNav


