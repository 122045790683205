import { extendTheme, ChakraProvider } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: '"Inter", sans-serif',
    body: '"Inter", sans-serif',
  },
  components: {
    Link: {
      variants: {
        primaryButton: {
          fontFamily: "Inter",
          bg: "#0A090B",
          color: "rgb(255, 255, 255)",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "600",
          borderRadius: "8px",
          height: "44px",
          paddingX: "16px",
          transition:
            "background-color 0.15s cubic-bezier(0, 0, 0.2, 1), color 0.15s cubic-bezier(0, 0, 0.2, 1)",
          fontFamily: "'Basier Square', sans-serif",
          _hover: {
            bg: "#4F4D55", // Example hover state change
          },
          _disabled: {
            bg: "#0A090B", // Disabled background should stay consistent
            color: "rgb(255, 255, 255)", // Text color also remains consistent
            opacity: 0.6, // Lower opacity to indicate the button is disabled
            _hover: {
              bg: "#0A090B", // Ensure the background remains the same on hover
              opacity: 0.6, // Maintain the same opacity on hover to avoid disappearing
            },
          },
          margin: "0px",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          
        },

        landingButton: {
          bg: "#1751D0",
          color: "rgb(255, 255, 255)",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "500",
          letterSpacing: "-0.128px",
          borderRadius: "16px",
          height: "44px",
          paddingX: "16px",
          transition:
            "background-color 0.15s cubic-bezier(0, 0, 0.2, 1), color 0.15s cubic-bezier(0, 0, 0.2, 1)",
          fontFamily: "'Basier Square', sans-serif",
          _hover: {
            bg: "rgb(66, 66, 66)", // Example hover state change
          },
        },
      },
    },

    Button: {
      variants: {
        primaryButton: {
          fontFamily: "Inter",
          bg: "#0A090B",
          color: "rgb(255, 255, 255)",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "600",
          borderRadius: "8px",
          height: "44px",
          paddingX: "16px",
          transition:
            "background-color 0.15s cubic-bezier(0, 0, 0.2, 1), color 0.15s cubic-bezier(0, 0, 0.2, 1)",
          fontFamily: "'Basier Square', sans-serif",
          _hover: {
            bg: "#4F4D55", // Example hover state change
          },
          _disabled: {
            bg: "#0A090B", // Disabled background should stay consistent
            color: "rgb(255, 255, 255)", // Text color also remains consistent
            opacity: 0.6, // Lower opacity to indicate the button is disabled
            _hover: {
              bg: "#0A090B", // Ensure the background remains the same on hover
              opacity: 0.6, // Maintain the same opacity on hover to avoid disappearing
            },
          },
        },

        secondaryButton: {
          fontFamily: "Inter",
          bg: "#ffffff",
          color: "#0A090B",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "600",
          border: "1px solid #E7E7E7",
          borderRadius: "8px",
          height: "44px",
          paddingX: "16px",
          transition:
            "background-color 0.15s cubic-bezier(0, 0, 0.2, 1), color 0.15s cubic-bezier(0, 0, 0.2, 1)",
          fontFamily: "'Basier Square', sans-serif",
          _hover: {
            bg: "#F5F5F5", // Example hover state change
            color: "#0A090B",
          },
          _disabled: {
            bg: "#0A090B", // Keep the background color consistent
            color: "rgb(255, 255, 255)", // Maintain text color
            opacity: 0.6, // Optionally, you can set an opacity to indicate the button is disabled
          },
        },

        stripeButton: {
          fontFamily: "Inter",
          bg: "#1751D0",
          color: "rgb(255, 255, 255)",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "600",
          borderRadius: "8px",
          height: "44px",
          paddingX: "16px",
          transition:
            "background-color 0.15s cubic-bezier(0, 0, 0.2, 1), color 0.15s cubic-bezier(0, 0, 0.2, 1)",
          fontFamily: "'Basier Square', sans-serif",
          _hover: {
            bg: "#113B98", // Example hover state change
          },
        },

        largeButton: {
          fontFamily: "Inter",
          bg: "#0A090B",
          color: "rgb(255, 255, 255)",
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: "600",
          borderRadius: "8px",
          height: "44px",
          paddingX: "16px",
          transition:
            "background-color 0.15s cubic-bezier(0, 0, 0.2, 1), color 0.15s cubic-bezier(0, 0, 0.2, 1)",
          fontFamily: "'Basier Square', sans-serif",
          _hover: {
            bg: "#4F4D55", // Example hover state change
          },
        },

        destruction: {
          fontFamily: "Inter",
          bg: "#CC1D1D",
          color: "rgb(255, 255, 255)",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "600",
          borderRadius: "8px",
          height: "44px",
          paddingX: "16px",
          transition:
            "background-color 0.15s cubic-bezier(0, 0, 0.2, 1), color 0.15s cubic-bezier(0, 0, 0.2, 1)",
          fontFamily: "'Basier Square', sans-serif",
          _hover: {
            bg: "#BC1C1C", // Example hover state change
          },
        },
      },
    },

    Box: {
      variants: {
        secondaryButton: {
          fontFamily: "Inter",
          bg: "#ffffff",
          color: "#0A090B",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "600",
          border: "1px solid #E7E7E7",
          borderRadius: "8px",
          height: "44px",
          paddingX: "16px",
          transition:
            "background-color 0.15s cubic-bezier(0, 0, 0.2, 1), color 0.15s cubic-bezier(0, 0, 0.2, 1)",
          fontFamily: "'Basier Square', sans-serif",
          _hover: {
            bg: "#4F4D55", // Example hover state change
          },
        },
        listItem: {
          display: "flex",
          flexDirection: "row",
          border: "1px solid #E7E7E7",
          p: "4px", // Shorthand for padding
        },
      },
    },

    Select: {
      variants: {
        primary: {
          field: {
            border: "1px solid #DCDCDE",
            fontFamily: "Inter", // Ensuring consistent font usage
            backgroundColor: "#111111", // Dark background
            color: "rgb(255, 255, 255)", // White text color
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "600",
            borderRadius: "8px",
            height: "48px",
            px: "16px", // Padding on the left and right
            py: "2px",
            transition:
              "background-color 0.15s cubic-bezier(0, 0, 0.2, 1), color 0.15s cubic-bezier(0, 0, 0.2, 1)",
            _hover: {
              backgroundColor: "rgb(66, 66, 66)", // Lighter dark shade on hover
            },
            _focus: {
              // Optional: style when the select is focused
              borderColor: "rgb(255, 255, 255)", // White border on focus
              boxShadow: "0 0 0 1px rgb(255, 255, 255)", // Adding a subtle focus ring
            },
          },
        },
      },
    },

    Text: {
      variants: {
        H1: {
          fontSize: "28px",
          lineHeight: "40px",
          letterSpacing: "-0.576px",
          fontWeight: "600",
          margin: "0",
          WebkitFontSmoothing: "antialiased",
        },

        H2: {
          fontSize: "24px",
          lineHeight: "32px",
          fontWeight: "600",
          letterSpacing: "-0.192px",
          color: "rgb(17, 17, 17)", // Assuming the same text color as previous variants
          margin: "0",
          WebkitFontSmoothing: "antialiased",
        },

        H3: {
          fontSize: "20px",
          lineHeight: "32px",
          fontWeight: "500",
          letterSpacing: "-0.192px",
          color: "rgb(17, 17, 17)", // Assuming the same text color as previous variants
          margin: "0",
          WebkitFontSmoothing: "antialiased",
        },
        PageNavHeading: {
          fontFamily: "Inter",
          fontSize: "13px",
          fontWeight: 475, // Chakra UI might not support this specific value, you may need to use 'normal' or 'bold'
          lineHeight: "12px",
          textAlign: "left",
          letterSpacing: "normal", // Assuming normal letter-spacing since it's not specified
          color: "#7C8087",
        },
        formHeading: {
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "500",
          letterSpacing: "-0.128px",
          color: "rgb(17, 17, 17)",
          margin: "0 0 6px 0",
          WebkitFontSmoothing: "antialiased",
        },

        inactiveNav: {
          fontSize: "16px",
          lineHeight: "24px",
          color: "#989898",
          WebkitFontSmoothing: "antialiased",
          _hover: { color: "#0A090B" },
        },

        listItemHeading: {
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: "600",
          letterSpacing: "-0.128px",
          color: "#0A090B",
          WebkitFontSmoothing: "antialiased",
        },

        listItemSubHeading: {
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "400",
          letterSpacing: "-0.128px",
          color: "#0A090B",
          WebkitFontSmoothing: "antialiased",
        },

        listItemMicro: {
          fontSize: "14px",
          lineHeight: "24px",
          fontWeight: "400",
          letterSpacing: "-0.128px",
          color: "#656260",
          WebkitFontSmoothing: "antialiased",
        },
      },
    },

    FormLabel: {
      variants: {
        formHeading: {
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "500",
          letterSpacing: "-0.128px",
          color: "rgb(17, 17, 17)",
          margin: "0 0 6px 0",
          WebkitFontSmoothing: "antialiased",
        },
      },
    },

    Input: {
      variants: {
        input: {
          field: {
            backgroundColor: "#ffffff",
            color: "rgb(17, 17, 17)",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "-0.128px",
            fontWeight: "500",
            paddingTop: "12px",
            paddingBottom: "12px",
            paddingLeft: "16px",
            paddingRight: "16px",
            borderRadius: "8px",
            height: "48px",
            fontFamily: "'Basier Square', sans-serif",
            WebkitFontSmoothing: "antialiased",
            margin: "0",
            padding: "12px 16px",
            border: "1px solid #DCDCDE",
          },
        },
      },
    },

    Textarea: {
      variants: {
        input: {
          field: {
            backgroundColor: "#ffffff",
            color: "rgb(17, 17, 17)",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "-0.128px",
            fontWeight: "500",
            paddingTop: "12px",
            paddingBottom: "12px",
            paddingLeft: "16px",
            paddingRight: "16px",
            borderRadius: "8px",
            height: "48px",
            fontFamily: "'Basier Square', sans-serif",
            WebkitFontSmoothing: "antialiased",
            margin: "0",
            padding: "12px 16px",
            border: "1px solid #DCDCDE",
          },
        },
      },
    },

    Tab: {
      variants: {
        default: {
          color: "#0A090B",
          backgroundColor: "#0A090B",
        },
      },
    },
  },
});

export default theme;
