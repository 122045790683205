import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { ChakraProvider } from '@chakra-ui/react';
import theme from "./theme";


import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";

import Home from "./routes/Home";

import MyNavbar from "./components/nav/MyNavbar";
import Landing from "./routes/Landing";
import Register from "./components/auth/Register";
import RegisterSubscriber from "./components/auth/RegisterSubscriber";
import LoginSubscriber from "./components/auth/LoginSubscriber";

import VerifyCheckoutSession from "./components/dashboard/VerifyCheckoutSession";

import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./routes/Dashboard";
import Payout from "./routes/Payout";
import AllPosts from "./routes/AllPosts";

import EditMembershipDetails from "./routes/EditMembershipDetails";
import MembershipDetails from "./routes/MembershipDetails";
import OnboardingMembershipDetails from "./routes/OnboardingMembershipDetails";
import OnboardingStripe from "./routes/OnboardingStripe";
import OnboardingPrices from "./routes/OnboardingPrices";


import AccountSettingsDetails from "./components/AccountSettings/AccountSettingsDetails";
import EditAccountSettings from "./components/AccountSettings/EditAccountSettings";
import ResetPassword from "./components/AccountSettings/ResetPassword";

import AccountSettings from "./routes/AccountSettings";

import ViewPublicMemberPage from "./routes/ViewPublicMemberPage"
import PostWithModal from "./routes/PostWithModal"

import ShowMemberPage from "./components/membersPage/ShowMemberPage"

import NewPost from "./routes/NewPost";
import NewPostDesign from "./routes/NewPostDesign";
import NewPostCode from "./routes/NewPostCode";


import ViewPost from "./routes/ViewPost";
import PreviewPostModal from "./components/membersPage/PreviewPostModal"

import NotFound from "./routes/NotFound";
import InactiveUserPage from "./routes/InactiveUserPage";

import DribbbleCallback from "./components/posts/DribbbleCallback";
import DribbbleUpload from "./components/posts/DribbbleUpload";

import InstagramCallback from "./components/posts/InstagramCallback";
import InstagramUpload from "./components/posts/InstagramUpload";

//import isCreator from "./components/auth/isCreator";

import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import OnboardingOverview from "./routes/OnboardingOverview";

// Extend the theme to include custom colors, fonts, etc.



// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);

  // If the user is a creator (has a connected account ID then send to dashboard if not send to Home)
  // const isCreatorValue = await isCreator(decoded.id)
  // console.log(isCreatorValue)

  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = "./login";
  }
}
class App extends Component {
  render() {
    return (
      <ChakraProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <div>
            <Routes>

            <Route exact path="/" element={<Landing />} />

            <Route exact path="/home" element={<Home/>} />
            <Route exact path="/signup" element={<Register/>} />
            <Route exact path="/register/subscriber" element={<RegisterSubscriber/>} />
            <Route exact path="/login/subscriber" element={<LoginSubscriber/>} />

            <Route exact path="/login" element={<Login/>} />
            <Route exact path="/dashboard" element={<Dashboard/>} />
            <Route exact path="/checkout/verify" element={<VerifyCheckoutSession/>} />


            <Route exact path="/u/:username" element={<ViewPublicMemberPage/>} />
            <Route path="/u/:username/post/:postId" element={<ViewPublicMemberPage />} />


            <Route exact path="/user" element={<ShowMemberPage/>} />

            <Route exact path="/payout" element={<Payout/>} />

            <Route exact path="/membership-details" element={<MembershipDetails/>} />
            <Route exact path="/edit-membership-details" element={<EditMembershipDetails/>} />
            <Route exact path="/membership/onboarding/1" element={<OnboardingMembershipDetails/>} />
            <Route exact path="/membership/onboarding/2" element={<OnboardingStripe/>} />
            <Route exact path="/membership/onboarding/3" element={<OnboardingPrices/>} />


            <Route exact path="/account-settings" element={<AccountSettingsDetails/>} />
            <Route exact path="/edit-account-settings" element={<AccountSettings/>} />
            <Route exact path="/reset-password" element={<ResetPassword/>} />

            <Route exact path="/creator/posts" element={<AllPosts/>} />

            <Route exact path="/new/post" element={<NewPost/>} />
            <Route exact path="/new/post/design" element={<NewPostDesign/>} />
            <Route exact path="/new/post/code" element={<NewPostCode/>} />


            <Route exact path="/post/:id" element={<ViewPost/>} />
            <Route exact path="/post/preview/:id" element={<PreviewPostModal/>} />

            <Route exact path="/oauth/dribbble" element={<DribbbleCallback/>} />
            <Route exact path="/upload/dribbble" element={<DribbbleUpload/>} />

            
            <Route exact path="/oauth/instagram" element={<InstagramCallback/>} />
            <Route exact path="/upload/instagram" element={<InstagramUpload/>} />



            <Route path='*' element={<NotFound />} />
            <Route exact path="/inactive-user" element={<InactiveUserPage />} />


            </Routes>


          </div>
        </Router>
      </Provider>
    </ChakraProvider>

    );
  }
}
export default App;
