import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Input,
  Textarea,
  Image,
  Center,
  Stack,
  Text,
  Flex
} from "@chakra-ui/react";
import DribbbbleLogin from "./DribbbbleLogin"; // Adjust import path as necessary

const NewPostForm = ({
  formData,
  onChange,
  onSubmit,
  submitLoading,
  inputRef,
  handleImageChange,
  image,
  errors,
  submitDisabled,
}) => (
  <form onSubmit={onSubmit}>
    <Stack spacing={4} pb={16}>
      {!image ? (
        <FormControl isRequired>
          <FormLabel>Cover Image</FormLabel>
          <VStack
            h="350px"
            backgroundColor="#F9FBFC"
            w="full"
            justifyContent="center"
            py="16px"
            borderRadius="md"
            borderWidth="1px"
          >
            <Text variant="H3">Add a cover photo</Text>
            <HStack gap={2}>
              <DribbbbleLogin />
              <Button
                variant="primaryButton"
                onClick={() => inputRef.current.click()}
              >
                Upload Image
              </Button>
            </HStack>
            <Input
              type="file"
              hidden
              ref={inputRef}
              accept="image/png, image/jpeg, image/gif, image/webp"
              name="media"
              onChange={handleImageChange}
            />
          </VStack>
          {errors.image && <Text color="red.500">{errors.image}</Text>}
        </FormControl>
      ) : (
        <FormControl isRequired>
          <FormLabel>Cover Image</FormLabel>
          <Center>
            <VStack w="400px" align="center" py="16px">
              <Image w="full" src={image} />
              <Button
                w="full"
                variant="secondaryButton"
                onClick={() => inputRef.current.click()}
              >
                Change Image
              </Button>
              <input
                type="file"
                hidden
                ref={inputRef}
                accept="image/png, image/jpeg, image/gif, image/webp"
                name="media"
                onChange={handleImageChange}
              />
            </VStack>
          </Center>
        </FormControl>
      )}
      <FormControl isRequired>
        <FormLabel>Title</FormLabel>
        <Input name="title" value={formData.title} onChange={onChange} />
        {errors.title && <Text color="red.500">{errors.title}</Text>}
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Design URL</FormLabel>
        <Input
          name="url"
          value={formData.url}
          onChange={onChange}
          placeholder="Enter design link..."
        />
        <Text mt={2} fontSize="16px">{errors.url && <Text color="red.500">{errors.url}</Text>}</Text>
      </FormControl>

      <FormControl>
        <FormLabel>Description</FormLabel>
        <Textarea
          name="description"
          value={formData.description}
          onChange={onChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Tags</FormLabel>
        <Input name="tags" value={formData.tags} onChange={onChange} />
      </FormControl>
      
      <Flex align="left">

      <Button
        isDisabled={submitDisabled}
        variant='primaryButton'
        isLoading={submitLoading}
        loadingText="Creating New Post..."
        onClick={onSubmit}
      >
        Upload
      </Button>

      </Flex>

      {errors.post && <Text color="red.500">{errors.post}</Text>}
    </Stack>
  </form>
);

export default NewPostForm;
