import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Alert,
  Badge,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  HStack,
  Flex,
} from "@chakra-ui/react";

const StripeAccountRequirementsCheck = () => {
  const auth = useSelector((state) => state.auth);
  const [profileError, setProfileError] = useState(false);
  const [stripeError, setStripeError] = useState(false);
  const [requirementsDue, setRequirementsDue] = useState(false);

  const [chargesEnabled, setChargesEnabled] = useState(false);
  const [payoutsEnabled, setPayoutsEnabled] = useState(false);


  useEffect(() => {
    const fetchStripeAccountDetails = async () => {
      try {
        // Reset error states at the beginning of the call
        setProfileError(false);
        setStripeError(false);

        // Get the user profile first
        const profileResponse = await axios.post("/api/users/get-profile", {
          id: auth.user.id,
        });
        const stripeConnectedAccountID =
          profileResponse.data.stripeConnectedAccountID;

        if (stripeConnectedAccountID) {
          // Now fetch Stripe connected account details
          const accountDetailsResponse = await axios.post(
            "/api/connectedAccounts/accountDetails",
            { id: stripeConnectedAccountID }
          );

          // Payments and Payouts
          console.log(accountDetailsResponse)
          console.log(accountDetailsResponse.data.charges_enabled)
          console.log(accountDetailsResponse.data.payouts_enabled)

          setChargesEnabled(accountDetailsResponse.data.charges_enabled)
          setPayoutsEnabled(accountDetailsResponse.data.payouts_enabled)

          const requirements = accountDetailsResponse.data.requirements.currently_due;

          if (requirements && requirements.length > 0) {
            setRequirementsDue(true); // Set requirementsDue to true if there are any requirements currently due
          }
        }

      } catch (error) {
        console.error("Error fetching details:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.context === "profile"
        ) {
          setProfileError(true); // Set profileError to true if there's an issue fetching the user profile
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.context === "stripe"
        ) {
          setStripeError(true); // Set stripeError to true if there's an issue fetching Stripe account details
        }
      }
    };

    fetchStripeAccountDetails();
  }, [auth.user.id]);

  return (
    <Flex w="full" direction="column">
      {profileError && (
        <Alert status="error" borderRadius="md">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Error fetching user profile.</AlertTitle>
            <AlertDescription display="block">
              Please try again later.
            </AlertDescription>
          </Box>
        </Alert>
      )}
      {stripeError && (
        <Alert status="error" borderRadius="md">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Error fetching Stripe account details.</AlertTitle>
            <AlertDescription display="block">
              Please try again later or check your Stripe dashboard.
            </AlertDescription>
          </Box>
          <Button
            colorScheme="red"
            onClick={() => {
              /* Implement redirect to Stripe dashboard */
            }}
          >
            Go to Dashboard
          </Button>
        </Alert>
      )}
      {requirementsDue && (
        <Alert status="warning" borderRadius="md">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Your Stripe account is missing essental information</AlertTitle>
            <HStack>
            <Box>Stripe Payments: {chargesEnabled ?   <Badge colorScheme='green'>Enabled</Badge> : <Badge colorScheme='red'>Disabled</Badge> }</Box>
            <Box>Stripe Payouts: {payoutsEnabled ? <Badge colorScheme='green'>Enabled</Badge> : <Badge colorScheme='red'>Disabled</Badge>}</Box>
            </HStack>
          </Box>
          <Button
            colorScheme="orange"
            as="a"
            href="/payout" // Replace this URL with the actual redirect target
          >
            View requirements
          </Button>
        </Alert>
      )}
    </Flex>
  );
};

export default StripeAccountRequirementsCheck;
