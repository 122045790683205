import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {loadStripe} from "@stripe/stripe-js";
import { useSelector } from 'react-redux';
import { Alert, InputGroup, ButtonGroup, Button, Form, FormGroup, FormControl } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';

import ResetPassword from "./ResetPassword";


import SideNav from "../nav/SideNav";




  const AccountSettingsDetails = (SuccessfulBanner) => {

  // Initialize state for form data
  const [formData, setFormData] = useState({});
  //const [SuccessfulBanner, setSuccessfulBanner] = useState(false);

  const auth = useSelector(state => state.auth);
  //console.log(auth);
  //console.log(auth.user.stripeConnectedAccountID);



  // Show exsiting data in the form
  useEffect(() => {
  // Make an axios GET request to retrieve the user's current profile information
  axios.post('/api/users/get-profile', {id: auth.user.id})
    .then(response => {
      //console.log(response);
      // Prepopulate the form with the user's current profile information
      setFormData({
        userId: auth.user.id,
        name: response.data.name,
        email: response.data.email,
        profileImage: response.data.plan.profileImage
        },() => console.log(formData));

    })
    .catch(error => {
      console.error(error);
    });

  }, []);


  return (

    <div>

      <Container className="page-container">
        <Row className="center-align">

          <Col xs={3}>
              <SideNav />
          </Col>

          <Col xs={9}>
            <div className="my-card">

              <div>

              <a href="/edit-account-settings"
                type="submit"
                className="bg-blue-500">
                Edit Account Details
              </a>
              </div>

              <label>Name</label>
              <p>{formData.name}</p>

              <label>Email</label>
              <p>{formData.email}</p>

              <div>
              <label>Reset Password</label>
              <a href="/reset-password"
                type="submit"
                className="bg-blue-500">
                Reset Password
              </a>
              </div>



              <label>Update Payment</label>
              {/* Render a component only if a paymnet card is saved*/}

            </div>
          </Col>

          </Row>
        </Container>





      </div>
      );
      }

      export default AccountSettingsDetails;
