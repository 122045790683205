// MembershipForm.js
import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  VStack,
  Avatar,
  HStack,
  Box,
} from "@chakra-ui/react";

import MembershipStatusButton from "./MembershipStatusButton";

const EditMembershipForm = ({
  formData,
  handleChange,
  handleImageChange,
  handleSubmit,
  submitLoading,
  inputRef,
  user,
}) => (
  <Box border="1px" borderColor="gray.200" p="24px" borderRadius={4}>
    <form onSubmit={handleSubmit}>
      <VStack spacing={4} align="start">
        
        {/* Profile image */}
        <FormControl>
          <FormLabel variant="formHeading" htmlFor="profileImage">
            Profile Image
          </FormLabel>
          <VStack borderRadius={4} backgroundColor="#F8F8F8" py="16px">
            <Avatar
              mb={2}
              bg="#105DFB"
              color="#ffffff"
              fontSize="md"
              name={user.name}
              src={formData.profileImage}
              size="lg"
              mr={4}
            />

            <Button
              bg="#ffffff"
              colorScheme="blue"
              variant="outline"
              size="md"
              onClick={() => {
                inputRef.current.click();
              }}
            >
              {" "}
              Change Avatar{" "}
            </Button>
            <input
              type="file"
              style={{ display: "none" }}
              ref={inputRef}
              accept="image/png, image/jpeg, image/gif, image/webp"
              name="profileImage"
              id="profileImage"
              onChange={handleImageChange}
            />
          </VStack>
        </FormControl>

          <HStack w="full">
          {/* Name input */}
          <FormControl>
            <FormLabel variant="formHeading" htmlFor="name">
              Full Name
            </FormLabel>
            <Input
              variant="input"
              placeholder="Enter your full name"
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              width="full"
            />
          </FormControl>

          {/* Username input */}
          <FormControl>
            <FormLabel variant="formHeading" htmlFor="username">
              Username
            </FormLabel>
            <Input
              variant="input"
              placeholder="Enter your username"
              type="text"
              name="username"
              id="username"
              value={formData.username}
              onKeyDown={(e) => {
                // Prevent spaces from being entered
                if (e.key === " " || e.key === "Spacebar") {
                  e.preventDefault();
                }
              }}
              onChange={handleChange}
              isDisabled={true} // This disables the input
            />
          </FormControl>
          </HStack>

          {/* Location input */}
          <FormControl>
            <FormLabel variant="formHeading" htmlFor="bio">
              City
            </FormLabel>
            <Input
              variant="input"
              placeholder="Where are you based?"
              type="text"
              name="location"
              id="location"
              value={formData.location}
              onChange={handleChange}
            />
          </FormControl>

        {/* Bio input */}
        <FormControl>
          <FormLabel variant="formHeading" htmlFor="bio">
            About you
          </FormLabel>
          <Input
            variant="input"
            size="md"
            placeholder="Tell us about yourself"
            type="text"
            name="bio"
            id="bio"
            value={formData.bio}
            onChange={handleChange}
          />
        </FormControl>

        {/* Portfolio link input */}
        <FormControl>
          <FormLabel variant="formHeading" htmlFor="portfolioLink">
            Portfolio Link
          </FormLabel>
          <Input
            variant="input"
            placeholder="Enter the link to your porfolio"
            type="text"
            name="portfolioLink"
            id="portfolioLink"
            value={formData.portfolioLink}
            onChange={handleChange}
          />
        </FormControl>

        {/* Contact Email input */}
        <FormControl>
          <FormLabel variant="formHeading" htmlFor="contactEmail">
            Contact Email
          </FormLabel>
          <Input
            variant="input"
            placeholder="Enter the best email to contact you"
            type="text"
            name="contactEmail"
            id="contactEmail"
            value={formData.contactEmail}
            onChange={handleChange}
          />
        </FormControl>

        {/* Submit button */}
        <Button
          type="submit"
          variant="primaryButton"
          isLoading={submitLoading}
          loadingText="Updating"
          spinnerPlacement="start"
        >
          Update details
        </Button>

        </VStack>


    </form>
  </Box>
);

export default EditMembershipForm;
