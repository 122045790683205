import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  VStack,
  Switch,
  useToast,
  Text,
  FormControl,
  FormLabel,
  Badge,
  Stack,
  Link,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";

const TogglePageStatus = ({
  currentActiveStatus,
  id,
  disabled: externalDisabled,
  stripeID,
}) => {
  const [isActive, setIsActive] = useState(currentActiveStatus);
  const [internalDisabled, setInternalDisabled] = useState(false); // State to handle internal disabled logic
  const toast = useToast();
  const auth = useSelector((state) => state.auth); // Assuming you're using Redux to manage auth state

  useEffect(() => {
    // Function to fetch Stripe account details
    const fetchStripeAccountDetails = async () => {
      try {
        if (!auth.user.id) return; // Ensure we have a user ID before making the call

        if (!stripeID) return; // Check if the Stripe account ID is available

        const response = await axios.post(
          "/api/connectedAccounts/accountDetails",
          {
            id: stripeID,
          }
        );

        // Check if there are any requirements currently due
        const requirementsDue =
          response.data.requirements?.currently_due?.length > 0;

        // Disable the toggle if requirements are due
        setInternalDisabled(requirementsDue);

        // Disable Toggle if payments is disabled
        if (response.data.charges_enabled == false) {
          setInternalDisabled(true); // Disable the toggle in case of an error
        }
      } catch (error) {
        console.error("Error fetching Stripe account details:", error);
        setInternalDisabled(true); // Disable the toggle in case of an error
      }
    };

    fetchStripeAccountDetails();
  }, [auth.user.id, stripeID]); // Re-run when the user ID or Stripe Connected Account ID changes

  const handleChange = () => {
    // First, check if the toggle should be disabled based on external or internal conditions.
    if (externalDisabled || internalDisabled) return;

    // Call the API to update the membership status.
    // Note: Ensure `id` is correctly passed as the user's ID to this component.
    axios
      .put("/api/users/change-membership-status", {
        plan: { isActive: !isActive },
        userId: auth.user.id,
      })
      .then((response) => {
        // Update the isActive state based on the response from the server
        setIsActive(response.data.plan.isActive);

        // Optionally, show a toast notification here if you want to provide feedback
        toast({
          title: `Status Updated`,
          description: `Your page is ${
            response.data.plan.isActive ? "live!" : "deactivated"
          }.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error("Error updating page status:", error);

        // Show a toast notification here if you want to inform the user of the error
        toast({
          title: "Error updating page status",
          description:
            "There was a problem updating your page status. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  // Final disabled state considers both external and internal conditions
  const finalDisabledState = externalDisabled || internalDisabled;
  //console.log(externalDisabled, internalDisabled);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="left"
      alignItems="flex-start"
      background="#FFFFFF"
      border="1px solid #ECECED"
      boxShadow="0px 5px 13px -5px rgba(10, 9, 11, 0.05), 0px 2px 4px -1px rgba(10, 9, 11, 0.02)"
      borderRadius="8px"
      p={4}
    >
      <VStack
       p={0} m={0} display="flex" alignItems="left">
        <FormControl  p={0} m={0} display="flex" alignItems="center">
          <FormLabel htmlFor="go-live-switch" mb="0">
            <Text mb={0} variant="H3" as="b">
              My Channel
            </Text>
          </FormLabel>
          <Switch
            size="lg"
            id="go-live-switch"
            colorScheme="blue"
            isChecked={isActive}
            onChange={handleChange}
            isDisabled={finalDisabledState}
          />
          <Box ml={4}>
          {!isActive ? (
            <Text mb={0} fontSize="sm" color="gray.600">
              <Badge colorScheme="red"> Deactivated </Badge>
            </Text>
          ) : (
            <Stack direction={{ base: "row", md: "column" }} spacing={2}>
              <Text mb={0} fontSize="sm" color="green.600">
                <Badge colorScheme="green"> Live </Badge>
              </Text>
            </Stack>
          )}
          </Box>
        </FormControl>
        {finalDisabledState && (
          <Text m={0} fontSize="16px" color="red.500">
            You must complete all requirements before activating your channel.
          </Text>
        )}
      </VStack>
    </Box>
  );
};

export default TogglePageStatus;
