import React, { useEffect, useState, useRef } from "react";
import Faq from "react-faq-component";
import PublicNav from "../components/general/PublicNav";
import DetailsCard from "../components/landing/DetailsCard";

import "../landingStyle.css";

import Landing1 from "../Images/Landing1.png";
import Landing2 from "../Images/Landing2.png";
import Landing3 from "../Images/Landing3.png";
import Landing4 from "../Images/Landing4.png";
import Landing5 from "../Images/Landing5.png";
import Landing6 from "../Images/Landing6.png";

import {
  Box,
  Button,
  Flex,
  Link,
  Image,
  SimpleGrid,
  Text,
  useColorModeValue,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const Landing = () => {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const highlightColor = "#1964FF";
  const flexDirection = useBreakpointValue({ base: "column", md: "row" });

  const data = {
    title: "FAQ",
    rows: [
      {
        title: "What is UIMarketplace?",
        content: `UIMarketplace is a platform that makes it easy for designers and developers to upload and sell their UI components and designs as a subscription service. We are a marketplace where you can offer your design system, UI Mockups, or even individual components to a dedicated community of users.`,
      },
      {
        title: "How do I get started?",
        content: `Create a free UIMarketplace account and explore the platform.
          Set Up Monthly and Yearly Subscription Tiers: Determine your pricing and access levels.
          Upload Your Components: Import your designs from your favorite tools.
          Start Earning: Begin collecting subscriptions and grow your community.`,
      },
      {
        title: "Is UIMarketplace free?",
        content: `10% is deducted from each successful subscription payment as a platform fee.`,
      },
    ],
  };

  const styles = {
    // bgColor: 'white',
    titleTextColor: "#212121",
    titleTextSize: "48px",
    rowTitleColor: "#212121",
    rowTitleTextSize: "22px",
    rowContentColor: "#212121",
    rowContentTextSize: "18px",
    rowContentPaddingTop: "10px",
    rowContentPaddingBottom: "10px",
    transitionDuration: ".5s",
    timingFunc: "ease",
    rowTitleTextSize: "large",
  };

  const config = {
    animate: true,
    arrowIcon: <ChevronDownIcon boxSize={8} />,
    tabFocus: true,
  };

  const faqRef = useRef(null); // Create a ref to the FAQ component

  const handleFaqClick = () => {
    // Scroll to the FAQ section smoothly
    faqRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const paddingx = useBreakpointValue({ base: "8", md: "16" });
  const paddingy = useBreakpointValue({ base: "2", md: "2" });

  return (
    <Box px={paddingx} py={paddingy} bg="#ffffff" minH="100vh" minW="100vw" color="gray.700">
      {/* Header */}
      <PublicNav />

      <VStack gap={16} >
        {/* Hero Section */}
        <Box w="100%" alignItems="center" py={16}>
          <VStack>
            <Text
              color="#0F0F10"
              fontSize={58.1}
              fontFamily="Inter"
              fontWeight={700}
              textTransform="capitalize"
              lineHeight="70px"
              wordWrap="break-word"
              textAlign="center"
            >
              Member-only access for Your <br /> UI Components
            </Text>

            <Text
              textAlign="center"
              fontWeight="450"
              color="#212121"
              fontSize="18px"
            >
              We makes it easy for you to launch a subscription business for all
              your UI assets.
            </Text>
            <Button
              bg="#2261EB"
              color="white"
              fontSize="17px"
              lineHeight="24px"
              fontWeight="600"
              letterSpacing="-0.128px"
              borderRadius="8px"
              h="52px"
              px="16px"
              transition="background-color 0.15s cubic-bezier(0, 0, 0.2, 1), color 0.15s cubic-bezier(0, 0, 0.2, 1)"
              _hover={{ bg: "#1751D0" }}
              as={Link}
              href="/signup"
              w="350px"
              style={{ textDecoration: "none"}}
            >
              Start your channel
            </Button>
          </VStack>
        </Box>

        {/* Details Section */}
        <Box maxW="100%">
          <SimpleGrid maxW="100%" columns={{ base: 1, md: 3 }} spacing={16}>
            <DetailsCard
              Header="Earn Recurring Revenue"
              Body="Turn your UI components into a recurring revenue stream. Offer subscriptions and start earning passive income from your design work."
              cover={Landing1}
            />

            <DetailsCard
              Header="Publish your Design Files"
              Body="Centralize your UI assets and make them instantly accessible to your members. Share your Figma, Sketch, InVision Studio, and more – all in one place."
              cover={Landing2}
            />

            <DetailsCard
              Header="Share Front-end Code"
              Body="Streamline your workflow and give members the complete package. Share both the design and the code for your UI components, making it easy for them to use and customize."
              cover={Landing3}
            />

            <DetailsCard
              Header="Showcase your Channel"
              Body="Create a thriving community around your UI work. Control access to exclusive resources, private content, and behind-the-scenes insights for your members."
              cover={Landing4}
            />

            <DetailsCard
              Header="Get Paid with Stripe"
              Body="Integrate seamlessly with Stripe for effortless payments. Collect recurring subscriptions, manage your earnings, and keep your business running smoothly. "
              cover={Landing5}
            />

            <DetailsCard
              Header="Upload with Dribbble"
              Body="Showcase your best work instantly. Import your Dribbble shots into your membership area, easily selling your existing work."
              cover={Landing6}
            />
          </SimpleGrid>
        </Box>

        {/* FAQ Section */}
        <Box pb={108} ref={faqRef}>
          <Faq data={data} styles={styles} config={config} />
        </Box>
      </VStack>
    </Box>
  );
};

const FeatureCard = ({
  icon,
  title,
  description,
  children,
  buttonText,
  imageRight,
  flexDirection,
  highlightColor,
}) => {
  return (
    <Flex
      flexDirection={flexDirection}
      textAlign="left"
      gap={{ base: 8, md: 72 }}
      py="72px"
      alignItems="center"
    >
      {!imageRight && (
        <Box>
          <Image
            src={icon}
            alt={title}
            boxSize={{ base: "200px", md: "520px" }}
            mx="auto"
          />
        </Box>
      )}
      <Flex
        flexDirection="column"
        width={{ base: "100%", md: "545px" }}
        alignItems="left"
      >
        <Text color="#212121" as="b" fontSize="42px" lineHeight="1.2" mb={4}>
          {title}
        </Text>
        <Text color="#666666" lineHeight="7" fontSize="17px" mb={4}>
          {description}
        </Text>
        {children}
        {buttonText && (
          <Button color="#ffffff" bg={highlightColor} mt={4}>
            {buttonText}
          </Button>
        )}
      </Flex>
      {imageRight && (
        <Box>
          <Image
            src={icon}
            alt={title}
            boxSize={{ base: "200px", md: "520" }}
            mx="auto"
          />
        </Box>
      )}
    </Flex>
  );
};

const StepCard = ({ icon, title, description }) => {
  return (
    <Box py={8} textAlign="left">
      <Image src={icon} alt={title} w="824px" mx="auto" mb={2} />
      <Text color="#212121" fontWeight="600" fontSize="20px" mb={2}>
        {title}
      </Text>
      <Text fontSize="17px" lineHeight="7" color="#666666">
        {description}
      </Text>
    </Box>
  );
};

export default Landing;
