import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import Moment from "react-moment";

import {
  Stack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  VStack,
  Flex,
  Avatar,
  Box,
  Badge,
  Text,
  Spinner,
  Center,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

function StatusBadge({ status }) {
  // Determine badge color based on the status
  const badgeColorScheme = status === "active" ? "green" : "orange";

  return (
    <Box p={0} m={0}>
      <Badge colorScheme={badgeColorScheme}>{status}</Badge>
    </Box>
  );
}

function SubscriptionTable() {
  const [loading, setLoading] = useState(true);

  const [subscriptions, setSubscriptions] = useState([]);
  const [newSubscriptions, setNewSubscriptions] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [profile, setProfile] = useState({});

  const [stripeFee, setStripeFee] = useState(null);
  const [platformFee, setPlatformFee] = useState(null);

  const data = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profileData = await axios.post("/api/users/get-profile", {
          id: data.user.id,
        });
        setProfile(profileData.data);
        //console.log(profileData.data);

        fetchSubscriptions(profileData.data.stripeConnectedAccountID);
      } catch (err) {
        //console.log(err);
      }
    };

    const fetchSubscriptions = async (id) => {
      //console.log(id);
      let newSubs = [];
      try {
        const subscriptions = await axios.post(
          "/api/subscription/list-subscriptions",
          {
            connectedAccountId: id,
          }
        );
        //console.log(subscriptions);
        setSubscriptions(subscriptions.data);

        // For each Subscription get its customer info
        for (const subscription of subscriptions.data) {
          const customer = await axios.post("/api/subscription/get-customer", {
            connectedAccountId: id,
            customerId: subscription.customer,
          });

          ////console.log(customer.data);
          newSubs.push({
            id: subscription.id,
            name: subscription.name,
            status: subscription.status,
            amount: subscription.items.data[0].price.unit_amount,
            interval: subscription.plan.interval,
            start_date: subscription.start_date,
            applicationFeePercent: subscription.application_fee_percent,
            customerName: customer.data.name,
            customerEmail: customer.data.email,
          });
        }

        setNewSubscriptions(newSubs);
        setLoading(false);
      } catch (error) {
        // setError(error);
        // setLoading(false);
        console.error(error);
      }
    };

    fetchProfile();
  }, []);

  const handleViewInfo = async (subscriptionId) => {
    try {
      const response = await axios.post("/api/subscription/getInfo", {
        id: subscriptionId,
        connectedAccountId: profile.stripeConnectedAccountID,
      });
      //console.log(response.data);

      const customer = await axios.post("/api/subscription/get-customer", {
        connectedAccountId: profile.stripeConnectedAccountID,
        customerId: response.data.customer,
      });

      setSelectedSubscription({
        id: response.data.id,
        name: response.data.name,
        status: response.data.status,
        amount: response.data.items.data[0].price.unit_amount,
        interval: response.data.plan.interval,
        start_date: response.data.start_date,
        applicationFeePercent: response.data.application_fee_percent,
        customerName: customer.data.name,
        customerEmail: customer.data.email,
      });

      var sub = response.data;

      // Calculate Stripe's proccessing fee
      var unitPrice = sub.items.data[0].price.unit_amount; // Unit price in cents
      var processingFee = unitPrice * 0.029 + 30; // Calculate processing fee in cents
      var stripeFeeDollars = "$" + (processingFee / 100).toFixed(2); // Convert to dollars with dollar sign and two decimal places
      //console.log(processingFee);
      //console.log(stripeFeeDollars); // Outputs: "59"
      setStripeFee(stripeFeeDollars);

      // Calculate Platform fee
      var applicationFeePercent = sub.application_fee_percent; // Get the application fee percent
      var platformFee = (unitPrice * applicationFeePercent) / 100; // Calculate the platform fee in cents
      var platformFeeDollars = "$" + (platformFee / 100).toFixed(2); // Convert to dollars with dollar sign and two decimal places
      //console.log(platformFee);
      //console.log(platformFeeDollars); // Outputs the platform fee in dollars, e.g. "$0.50" for a 50 cent platform fee
      setPlatformFee(platformFeeDollars);

      // Open Modal
      setIsOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return (
      <Center h="50vh">
        <Spinner />
      </Center>
    );
  }

  return (
    <Box h="full">
      <Table borderWidth="1px" borderRadius="md" h="full">
        <Thead>
          <Tr>
            <Th>User</Th>
            <Th>Status</Th>
            <Th>Subscription</Th>
            <Th>Created</Th>
            <Th></Th>
          </Tr>
        </Thead>

        <Tbody h="full">
          {newSubscriptions &&
            loading == false &&
            newSubscriptions.map((subscription) => (
              <Tr key={subscription.id}>
                <Td>
                    <Box>
                      <Text color="#222124" as="b">
                        {subscription.customerName}
                      </Text>
                      <Text m={0} color="#5F5F61">
                        {subscription.customerEmail}
                      </Text>
                    </Box>
                </Td>

                <Td>
                  <Box>
                  <Text color="#222124" as="b">
                  {subscription.status === 'active' ? (
                    <Badge colorScheme="green">{subscription.status}</Badge>
                  ) : subscription.status === 'cancelled' ? (
                    <Badge colorScheme="red">{subscription.status}</Badge>
                  ) : (
                    <Badge>{subscription.status}</Badge> // Default case for other statuses
                  )}
                </Text>
                  </Box>
                </Td>

                <Td>
                  <HStack>
                    <Text m={0} flex={1}>
                      ${(subscription.amount / 100).toFixed(2)} /{" "}
                      {subscription.interval}
                    </Text>
                  </HStack>
                </Td>

                <Td>
                  {subscription && subscription.start_date && (
                    <Moment format="MM-DD-YYYY">
                      {subscription.start_date * 1000}
                    </Moment>
                  )}
                </Td>

                <Td>
                  <Button
                    size="sm"
                    colorScheme="gray"
                    onClick={() => handleViewInfo(subscription.id)}
                  >
                    View Info
                  </Button>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>

      <Modal size="xl" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Subscription Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedSubscription && (
              <Box>
                <Stack spacing={8} mt={2}>


                  <Box alignItems="top" w="full" h="full">
                    <Text mb={0} fontWeight="bold">
                      Status:
                    </Text>
                    <StatusBadge status={selectedSubscription.status} />
                  </Box>

                  <HStack>
                    <Box w="full">
                      <Text mb={0} fontWeight="bold">
                        Customer Name:
                      </Text>
                      <Text>{selectedSubscription.customerName}</Text>
                    </Box>

                    <Box w="full">
                      <Text mb={0} fontWeight="bold">
                        Customer Email:
                      </Text>
                      <Text>{selectedSubscription.customerEmail}</Text>
                    </Box>
                  </HStack>

                  <HStack>
                    <Box w="full">
                      <Text mb={0} fontWeight="bold">
                        Stripe Proccessing Fee:
                      </Text>
                      <Text>{stripeFee}</Text>
                    </Box>

                    <Box w="full">
                      <Text mb={0} fontWeight="bold">
                        Platform Fee:
                      </Text>
                      <Text>{[platformFee]}</Text>
                    </Box>
                  </HStack>

                  <HStack>
                    <Box w="full">
                      <Text mb={0} fontWeight="bold">
                        Amount:
                      </Text>
                      <Text>
                        {(selectedSubscription.amount / 100).toFixed(2)}
                      </Text>
                    </Box>

                    <Box w="full">
                      <Text mb={0} fontWeight="bold">
                        Interval:
                      </Text>
                      <Text>{selectedSubscription.interval}</Text>
                    </Box>
                  </HStack>

                  <HStack>
                  <Box w="full">
                      <Text mb={0} fontWeight="bold">
                        Created At:
                      </Text>
                      {selectedSubscription &&
                        selectedSubscription.start_date && (
                          <Moment format="MM-DD-YYYY">
                            {selectedSubscription.start_date * 1000}
                          </Moment>
                        )}
                    </Box>

                    <Box w="full">
                      <Text mb={0} fontWeight="bold">
                        Subscription ID:
                      </Text>
                      <Text>{selectedSubscription.id}</Text>
                    </Box>
                  </HStack>
                </Stack>
              </Box>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setIsOpen(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default SubscriptionTable;
